import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom"
import Header from "./component/Header";
import Footer from "./component/Footer";
import Error from "./component/Error";
import Home from "./pages/Home";
import ContactUs from "./pages/ContactUs";
import Solutions from "./pages/Solutions";
import Industries from "./pages/Industries";

function App() {
  return (
      <BrowserRouter>
          <Header/>
          <Routes>
              <Route path={"/"} element={<Home/>}/>
              <Route path="*" element={<Error/>} />
              <Route path={"/contact-us"} element={<ContactUs/>} />
              <Route path={"/solutions"} element={<Solutions/>} />
              <Route path={"/industries"} element={<Industries/>} />
          </Routes>
          <Footer/>
      </BrowserRouter>
  );
}

export default App;
