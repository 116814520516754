import React, {useState} from "react";
import {
    AppBar,
    Box,
    Button,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    Toolbar,
    Typography
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import logo from "./img/logo.svg";
import banner from "./img/banner.png";
import {Link} from "react-router-dom";

export default function Header() {

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <Paper component="header" elevation={4} square={true}>
            <AppBar color="inherit" position="fixed">
                <Toolbar variant="regular" style={{backdropFilter: "blur(6px)"}}>
                    <Container maxWidth="xl" sx={{alignItems: 'center', display: 'flex'}}>
                        <Box>
                            <Link href="/">
                                <Box sx={{display: {xs: 'none', md: 'flex'}}}>
                                    <img src={banner} alt="logo" height="60px"/>
                                </Box>
                                <Box sx={{display: {xs: 'flex', sm: 'none'}}}>
                                    <img src={banner} alt="logo" height="40px"/>
                                </Box>
                            </Link>
                        </Box>
                        <Box sx={{flexGrow: 1}}></Box>
                        <Box sx={{flexGrow: 0, display: {xs: 'flex', md: 'none'}}}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon/>
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: {xs: 'block', md: 'none'},
                                }}
                            >
                                <div>
                                    <MenuItem>
                                        <Link to={"/"} style={{textDecoration: 'none', color: 'inherit'}}>
                                            <Typography textAlign="center">Home</Typography>
                                        </Link>
                                    </MenuItem>
                                    <MenuItem>
                                        <Link to={"/solutions"} style={{textDecoration: 'none', color: 'inherit'}}>
                                            <Typography textAlign="center">Services</Typography>
                                        </Link>
                                    </MenuItem>
                                    <MenuItem>
                                        <Link to={"/industries"} style={{textDecoration: 'none', color: 'inherit'}}>
                                            <Typography textAlign="center">Industries</Typography>
                                        </Link>
                                    </MenuItem>
                                    <MenuItem>
                                        <Link to={"/contact-us"} style={{textDecoration: 'none', color: 'inherit'}}>
                                            <Typography textAlign="center">Contact us</Typography>
                                        </Link>
                                    </MenuItem>
                                </div>
                            </Menu>
                        </Box>
                        <Box sx={{flexGrow: 0, display: {xs: 'none', md: 'flex'}}}>
                            <Link to={"/"} style={{textDecoration: 'none', color: 'inherit'}}>
                                <Button
                                    key='home'
                                    onClick={handleCloseNavMenu}
                                    sx={{my: 2, color: 'inherit', display: 'block'}}
                                >
                                    Home
                                </Button>
                            </Link>
                            <Link to={"/solutions"} style={{textDecoration: 'none', color: 'inherit'}}>
                                <Button
                                    key='solutions'
                                    onClick={handleCloseNavMenu}
                                    sx={{my: 2, color: 'inherit', display: 'block'}}
                                >
                                    Services
                                </Button>
                            </Link>
                            <Link to={"/industries"} style={{textDecoration: 'none', color: 'inherit'}}>
                                <Button
                                    key='industries'
                                    onClick={handleCloseNavMenu}
                                    sx={{my: 2, color: 'inherit', display: 'block'}}
                                >
                                    Industries
                                </Button>
                            </Link>
                            <Link to={"/contact-us"} style={{textDecoration: 'none', color: 'inherit'}}>
                                <Button
                                    key='contact'
                                    onClick={handleCloseNavMenu}
                                    sx={{my: 2, color: 'inherit', display: 'block'}}
                                >
                                    Contact us
                                </Button>
                            </Link>
                        </Box>
                    </Container>
                </Toolbar>
            </AppBar>
        </Paper>
    );
}