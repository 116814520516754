import React from "react";
import {Box, Card, CardContent, CardMedia, Container, Grid, Typography} from "@mui/material";
import Hero1 from "../component/img/business_deal.svg";
import Service1 from "../component/img/service4.svg";
import Service2 from "../component/img/service3.svg";
import Service3 from "../component/img/service5.svg";

export default function Home(){
    return (
        <Box component="main" sx={{pt:{xs:8, md:11}}}>
            <Container maxWidth="xl" sx={{my:3}}>
                <Grid container spacing={2} sx={{py:{xs:4, md:10}}}>
                    <Grid item xs={12} md={5}>
                        <Typography variant="h2" sx={{fontWeight: 750, fontSize:{xs: 30, md: 40}}} gutterBottom>{firstSection[0].Heading}</Typography>
                        <Typography sx={{fontSize:{xs: 16, md: 20}}} paragraph>{firstSection[0].Context}</Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <img src={Hero1} alt="Hero" style={{height: "auto", maxWidth: "100%"}}/>
                    </Grid>
                </Grid>
            </Container>
            <Container maxWidth="xl" sx={{my:3}}>
                <Box>
                    <Typography sx={{fontWeight: "bold", fontSize: {xs: "28px", md: "60px"}, textAlign: "center"}}>What we do?</Typography>
                    <Typography gutterBottom variant="h5" sx={{ textAlign: "center", pb:2}}>The service we provided</Typography>
                </Box>
                <Grid container spacing={4} justifyContent="center">
                    {service.map((item) => (
                        <Grid item xs={11} md={4}>
                            <Card variant="outlined">
                                <CardMedia
                                    component="img"
                                    image={item.path}
                                    alt={item.Alt}
                                    sx={{height: "300px"}}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" sx={{textAlign: "center"}}>{item.Name}</Typography>
                                    <Typography variant="body1" sx={{textAlign: "center"}}>{item.Intro}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
;}

const firstSection = [
    {
        "Heading": "Electronic Product Development and Procurement.",
        "Context": "Over 20 years of consumer electronic product development experience, with a robust IC supply chain. Ease your product development with A+."
    }
]

const service = [
    {
        "Name": "Software Design Consultant",
        "Intro": "Professional software engineering consulting, especially in Hardware control.",
        "Alt": "service1",
        "path": Service1
    },
    // {
    //     "Name": "IC Procurement",
    //     "Intro": "Over 20 years IC industries procurement and supply chain management.",
    //     "Alt": "service2",
    //     "path": Service2
    // },
    {
        "Name": "IC/Firmware Developing ",
        "Intro": "Software developing in Hardware control, such as CD Player, consumer product",
        "Alt": "service3",
        "path": Service3
    },
]