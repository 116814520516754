import React from "react";
import {Box, Container, Typography} from "@mui/material";

export default function Solutions(){
    return(
        <Container sx={{pt: {xs: 8, md: 11}}}>
            <Box sx={{pb: {xs:6, md: 8}, pt: {xs:6, md:6}, my: 10}}>
                <Typography variant="h2" align="center" gutterBottom>Great things coming soon.</Typography>
                <Typography variant="h5" align="center" gutterBottom>We are a small and experienced company with big ideas.</Typography>
            </Box>
        </Container>
    );
}