import {Box, Container, Divider, Grid, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import logo from "./img/logo.svg";

export default function Footer() {
    return (
        <Box
            component="footer"
        >
            <Divider component="hr"/>
            <Container maxWidth="xl" component="div" sx={{pt: 10}}>
                <Grid
                    container
                    justifyContent={{xs: "center", md: "space-between"}}
                    textAlign={{xs: "center", md: "left"}}
                    spacing={2}
                >
                    <Grid item xs={12} sx={{mb: 3}}>
                        <Link href="/">
                            <Box sx={{textAlign: 'inherit'}}>
                                <img src={logo} alt="logo" height="40px" width="40px"/>
                            </Box>
                        </Link>
                    </Grid>
                    <Grid item xs={8} md={3}>
                        <Typography component="p" variant="body2" sx={{mb: 5, fontSize: "0.875rem", lineHeight: 1.5}}>
                            {briefIntro}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <Grid container spacing={2} direction="row" justifyContent="space-between"
                              alignItems="flex-start">
                            <Grid item xs={12} md={6}>
                                <Typography variant="overline" display="block" gutterBottom sx={{fontWeight: 700}}>A
                                    PLUS INNOVATION</Typography>
                                <Link to={"/"} style={{textDecoration: 'none', color: 'inherit'}}>
                                    <Typography variant="body2" gutterBottom sx={{mt: 2}}>About us</Typography>
                                </Link>
                                <Link to={"/contact-us"} style={{textDecoration: 'none', color: 'inherit'}}>
                                    <Typography variant="body2" gutterBottom sx={{mt: 2}}>Contact us</Typography>
                                </Link>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="overline" display="block" gutterBottom
                                            sx={{fontWeight: 700}}>CONTACT</Typography>
                                <a href="mailto:service@aplus-innovation.com" style={{ color: 'inherit', textDecoration: 'none' }}>
                                    <Typography variant="body2" gutterBottom sx={{mt: 2}}>
                                        service@aplus-innovation.com
                                    </Typography>
                                </a>
                                <Typography variant="body2" gutterBottom sx={{mt: 2}}>Taipei, Taiwan</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Typography
                    textAlign={{xs: "center", md: "left"}}
                    sx={{
                        mt: 10,
                        lineHeight: 1.5,
                        pb: 5,
                        fontSize: "0.75rem",
                    }}
                >© {new Date().getFullYear()}. All rights reserved</Typography>
            </Container>
        </Box>
    );
}

const briefIntro = "A Plus Innovation has over 20 years of consumer electronics development experience. Helping customers in the pre-developing preparation.";