import React from "react";
import {Box, Grid} from "@mui/material";
import error from "./img/404.svg";

export default function Error(){
    return (
        <Box component="main" sx={{pt:{xs:8, md:11}}}>
            <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{minHeight: '40vh', textAlign: "center", py:12}}>
                <Grid item xs={12} md={8}>
                    <img src={error} alt="Error" style={{height: "auto", maxWidth: "100%"}}/>
                </Grid>
            </Grid>
        </Box>
    );
}