import React from "react";
import {Container, Typography, Box, TextField, Grid, Button} from "@mui/material";

export default function ContactUs() {
    return (
        <Container sx={{pt: {xs: 8, md: 11}}}>
            <Box sx={{pb: {xs:2, md: 6}, pt: {xs:2, md:4}}}>
                <Typography sx={{fontWeight: 'bold', typography: {xs: 'h4', md: 'h2'}}}>
                    Contact A-Plus
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    We appreciate your interest in A-Plus Innovation.
                </Typography>
            </Box>
            <Box>
                <Typography variant="h5" gutterBottom>
                    Send us a question
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Thank you for your interest in A-Plus Innovation's services. Please provide the following information about your business needs to help us serve you better. This information will enable us to route your request to the appropriate person. You should receive a response within one to two business days.
                </Typography>
                <Box component="form"
                     sx={{
                         border: 1,
                         borderRadius: 2,
                         p: 2,
                         minWidth: 300,
                         my: 2,
                         borderColor: 'grey.400'
                     }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                required
                                id="firstname"
                                label="First Name"
                                variant="standard"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                required
                                id="lastname"
                                label="Last Name"
                                variant="standard"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                required
                                id="email"
                                label="E-mail Address"
                                variant="standard"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="phone"
                                label="Phone number (Please include country code)"
                                variant="standard"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="companyName"
                                label="Company/Organization"
                                variant="standard"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="question"
                                label="How can we help you?"
                                variant="standard"
                                fullWidth
                                multiline
                                rows={5}
                            />
                        </Grid>
                    </Grid>
                    <Button variant="outlined" sx={{mt:2, px: 6}}>Submit</Button>
                </Box>
            </Box>
        </Container>
    );
}